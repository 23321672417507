import React, { useState } from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"

import styled from "styled-components"

import ComicsFilter from "../components/ComicsFilter"
import ComicGridItem from "../components/ComicGridItem"
import ComicGrid from "../components/ComicGrid"
import { Container } from "../components/Container"
import Comics from "../components/Comics"
const SearchComics = loadable(() => import("../components/SearchComics"))

const SEO = loadable(() => import("../components/seo"))
const Layout = loadable(() => import("../components/layout"))
const PageWrapper = loadable(() => import("../pageWrapper"))

export const query = graphql`
  query {
    allPopularComics(skip: 0, sort: { fields: [pullCount], order: DESC }) {
      nodes {
        title
        stockNumber
        seriesCode
        shipDate
        issue

        image {
          childImageSharp {
            fluid(maxWidth: 250) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

// export const Container = styled.div`
//   max-width: 1280px;
//   margin: 0 auto;
// `

const IndexPage = ({ data }) => {
  const comics = data.allPopularComics.nodes

  return (
    <PageWrapper>
      <Layout>
        <SEO title="Home" />
        <Comics comics={comics} />
      </Layout>
    </PageWrapper>
  )
}

export default IndexPage
